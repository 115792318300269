<template>
  <div>
    <div class="mt-2">
      <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
        Back
      </button>
      <button
        class="mb-2 btn btn-default btn-sm"
        @click="$router.push({ name: 'Bank Management Form' })"
      >
        Add Management
      </button>

      <div class="card shadow w-100 p-4">
        <loader v-if="!data_loaded" />
        <data-table
          v-if="data_loaded"
          id="bank_management"
          :options="dtb_options.extra_options"
          :actions="dtb_options.actions"
          :data="dtb_options.data"
          :columns="dtb_options.columns"
          :on_click="dtb_on_click"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../../components/Loader";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import DataTable from "@/components/Datatable";

export default {
  name: "bank_management",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      collections: [],
      //   user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Preview",
            btnClass: "btn btn-white text-info btn-sm",
            btnFn: (attachmentUrl) => {
              this.openFileInNewTab(attachmentUrl);
            },
            btnParamName: "attachmentUrl",
          },
          {
            btnText: "Download Attachement",
            btnClass: "btn btn-white text-success btn-sm",
            btnFn: (attachmentUrl) => {
              this.downloadImage(attachmentUrl);
            },
            btnParamName: "attachmentUrl",
          },
        ],
        columns: [
          {
            title: "Reason",
            data: "reason",
          },
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
        ],
        extra_options: {},
      },
    };
  },

  methods: {
    fetch_banks: async function () {
      const productsRef = collection(db, "banksDocuments");
      const q = query(
        productsRef,
        where("user_id", "==", this.$store.getters.getUserId)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        this.dtb_options.data = [];
        this.data_loaded = true;
      } else {
        querySnapshot.forEach((doc) => {
          let responseData = doc.data();
          responseData.uuid = doc.id;
          this.dtb_options.data.push(responseData);
          this.data_loaded = true;
        });
      }
    },

    async downloadImage(imageUrl) {
      try {
        // Fetch the image as a Blob
        const response = await fetch(imageUrl, { mode: "cors" });
        if (!response.ok) throw new Error("Network response was not ok.");

        // Convert the response to a Blob (binary data)
        const blob = await response.blob();

        // Create a download link for the Blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Set a default filename or extract one from the URL
        link.download = "downloaded-image.png";
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the Object URL and removing the link element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Image download failed:", error);
      }
    },

    async openFileInNewTab(fileUrl) {
      window.open(fileUrl, "_blank");
    },
  },

  created() {
    this.fetch_banks();
  },
};
</script>
<style lang="css" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #154462;
}
.nav-pills .nav-link {
  color: #154462;
}
.nav-pills .nav-link:hover {
  color: #154462;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: white;
}
</style>
